export function testCookie(): Promise<string> {
  window.document.cookie = `thirdPartyTestCookie=1; secure=true; samesite=none; path=/;`;
  return new Promise((resolve) => {
    (async function () {
      const hasCookie = /thirdPartyTestCookie=1/.test(document.cookie);

      let hasStorageAccess = true;
      if (
        window.document.hasStorageAccess &&
        typeof window.document.hasStorageAccess === "function"
      ) {
        hasStorageAccess = await window.document.hasStorageAccess();
      }

      const msg = hasCookie && hasStorageAccess ? "supported" : "unsupported";
      resolve(msg);
    })();
  });
}
