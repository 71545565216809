import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StaticContent from "./pages/StaticContent";
import NoContent from "./pages/NoContent";

const authContent =
  "It looks like your authentication token is renewed. Please refresh the page to view the step content.";

function App() {
  return (
    <Router basename="/">
      <Routes>
        <Route
          path="/login"
          element={<NoContent contentOverride={authContent} />}
        />
        <Route
          path="/logout"
          element={<NoContent contentOverride={authContent} />}
        />
        <Route
          path="content/:migrationPathName/:migrationNodeId/:version"
          element={<StaticContent />}
        />
        <Route path="*" element={<NoContent />} />
      </Routes>
    </Router>
  );
}

export default App;
