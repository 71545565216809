export enum Stage {
  DEVO = "devo",
  ALPHA = "alpha",
  GAMMA = "gamma",
  PROD = "prod",
}

export interface CognitoConfigOAuth {
  domain: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
}
export interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  mandatorySignIn: boolean;
  oauth: CognitoConfigOAuth;
}

export type ConfigByStage = {
  pfsEndpoint: string;
  cognito: CognitoConfig;
};
