import { ConfigByStage } from "./types/configTypes";

export const devConfigsByAlias: Record<string, ConfigByStage> = {
  default: {
    // Alpha env configs
    pfsEndpoint: "https://77izs03885.execute-api.us-west-2.amazonaws.com/alpha",
    cognito: {
      userPoolId: "us-west-2_vSJ2sGRSs",
      userPoolWebClientId: "760p54mpgoglmlllbn6a8kqi10",
      region: "us-west-2",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-590183735258.auth.us-west-2.amazoncognito.com",
        redirectSignIn: "http://localhost:3000/login",
        redirectSignOut: "http://localhost:3000/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
  payanami: {
    pfsEndpoint: "https://xn4mxeiytf.execute-api.us-west-2.amazonaws.com/devo",
    cognito: {
      userPoolId: "us-west-2_TYZ4Uck00",
      userPoolWebClientId: "21ks7haco91j2t2onp5sf9hrv2",
      region: "us-west-2",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-339712827993.auth.us-west-2.amazoncognito.com",
        redirectSignIn: "http://localhost:3000/login",
        redirectSignOut: "http://localhost:3000/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
  yifand: {
    pfsEndpoint: "https://dowm6fow98.execute-api.us-west-2.amazonaws.com/devo",
    cognito: {
      userPoolId: "us-west-2_wmZIpX8Og",
      userPoolWebClientId: "6avalnre6rvo4qvbbf3ihlrcvm",
      region: "us-west-2",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-519036487698.auth.us-west-2.amazoncognito.com",
        redirectSignIn: "http://localhost:3000/login",
        redirectSignOut: "http://localhost:3000/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
};
