import { devConfigsByAlias } from "./devoEnvConfigs";
import { ConfigByStage, Stage } from "./types/configTypes";

export const configsByStage: Record<Stage, ConfigByStage> = {
  [Stage.DEVO]: devConfigsByAlias["default"], // default -> alpha - ensure you have your configs in ./devoEnvConfigs.ts and replace it with your alias
  [Stage.ALPHA]: {
    pfsEndpoint: "https://77izs03885.execute-api.us-west-2.amazonaws.com/alpha",
    cognito: {
      userPoolId: "us-west-2_vSJ2sGRSs",
      userPoolWebClientId: "760p54mpgoglmlllbn6a8kqi10",
      region: "us-west-2",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-590183735258.auth.us-west-2.amazoncognito.com",
        redirectSignIn: "https://alpha.pathfinder.rfe.amazon.dev/login",
        redirectSignOut: "https://alpha.pathfinder.rfe.amazon.dev/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
  [Stage.GAMMA]: {
    pfsEndpoint: "https://ajh2vklppk.execute-api.us-east-1.amazonaws.com/gamma",
    cognito: {
      userPoolId: "us-east-1_fPHCmTDo8",
      userPoolWebClientId: "1je7d5rpd30fo3qbeo78af5jpb",
      region: "us-east-1",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-767398047215.auth.us-east-1.amazoncognito.com",
        redirectSignIn: "https://gamma.pathfinder.rfe.amazon.dev/login",
        redirectSignOut: "https://gamma.pathfinder.rfe.amazon.dev/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
  [Stage.PROD]: {
    pfsEndpoint: "https://li79ypeye6.execute-api.us-east-1.amazonaws.com/prod",
    cognito: {
      userPoolId: "us-east-1_4F0upoSul",
      userPoolWebClientId: "l5p5mhonfskqc5gna26lstng5",
      region: "us-east-1",
      mandatorySignIn: true,
      oauth: {
        domain:
          "rfe-pathfinder-service-website-users-339712911781.auth.us-east-1.amazoncognito.com",
        redirectSignIn: "https://pathfinder.rfe.amazon.dev/login",
        redirectSignOut: "https://pathfinder.rfe.amazon.dev/logout",
        scope: ["openid"],
        responseType: "code",
      },
    },
  },
};

export const STAGE_MAPPING: { [key: string]: Stage } = {
  localhost: Stage.DEVO,
  "alpha.pathfinder.rfe.amazon.dev": Stage.ALPHA,
  "gamma.pathfinder.rfe.amazon.dev": Stage.GAMMA,
  "pathfinder.rfe.amazon.dev": Stage.PROD,
};
