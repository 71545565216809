import { Alert, Container, Spinner } from "@amzn/awsui-components-react";
import { ReactNode } from "react";

export type BasePageProps = {
  busy: boolean;
  error?: boolean;
  errorMessage?: string;
  children?: ReactNode;
};

function BasePage(props: BasePageProps) {
  if (props.busy) return <Spinner size="large" data-testid="spinner" />;

  return (
    <Container>
      {props.error && (
        <Alert type="error" dismissible={false} data-testid="alert">
          There was an error in loading this page. Please try again later.
          {props.errorMessage && <p>{props.errorMessage}</p>}
        </Alert>
      )}
      {props.children}
    </Container>
  );
}

export default BasePage;
