import { Spinner } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { EnableThirdPartyCookieHelp } from "../static/EnableThirdPartyCookieHelp";
import { testCookie } from "../utils/cookieUtils";

export type ThirdPartyCookieEnableAreaProps = {
  children: React.ReactNode;
};

export const ThirdPartyCookieEnableArea: React.FC<
  ThirdPartyCookieEnableAreaProps
> = ({ children }) => {
  const [cookieEnabled, setIsCookieEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    const checkCookie = async () => {
      const result = await testCookie();
      setIsCookieEnabled(result === "supported");
    };
    checkCookie();
  }, []);

  if (cookieEnabled === null) {
    return <Spinner size="large" data-testid="spinner" />;
  }

  if (cookieEnabled) {
    return <>{children}</>;
  } else {
    return <EnableThirdPartyCookieHelp />;
  }
};
