import { Alert } from "@amzn/awsui-components-react";

export function EnableThirdPartyCookieHelp() {
  return (
    <Alert
      type="warning"
      statusIconAriaLabel="error"
      header="Third-Party Cookies Disabled"
    >
      <p>
        This web application needs the third-party cookies enabled on your
        browser to handle the SSO authentication. Please follow these
        instructions to enable third-party cookies:
      </p>
      <ul>
        <li>
          <strong>Google Chrome:</strong> Go to Settings &gt; Privacy and
          Security &gt; Site Settings &gt; Third-party cookies &gt; Allow all
          cookies.
        </li>
        <li>
          <strong>Mozilla Firefox:</strong> Go to Settings &gt; Privacy &amp;
          Security &gt; Enhanced Tracking Protection &gt; Custom &gt; Uncheck
          "Cookies" under "Third-party trackers".
        </li>
        <li>
          <strong>Safari:</strong> Go to Preferences &gt; Privacy &gt; Cookies
          and website data &gt; Allow from websites I visit.
        </li>
        <li>
          <strong>Microsoft Edge:</strong> Go to Settings &gt; Cookies and site
          permissions &gt; Cookies &gt; Don’t block cookies.
        </li>
      </ul>
      <p>After enabling third-party cookies, please refresh this page.</p>
    </Alert>
  );
}
