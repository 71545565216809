import { useEffect, useState } from "react";
import { UserProfile } from "../../types/user.type";
import { Auth } from "aws-amplify";
import { getCognitoUrl } from "../../configs/configUtils";
import { AppContextProvider } from "../../context/AppContext";
import { Spinner } from "@amzn/awsui-components-react";
import {
  redirectToOriginalUserRequestedUrlIfNeeded,
  saveRedirectionUrlIfNeeded,
} from "../../utils/autoRedirection";

export type AuthenticatedAreaProps = {
  children: React.ReactNode;
};

export const AuthenticatedArea: React.FC<AuthenticatedAreaProps> = ({
  children,
}) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserProfile | null>(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authenticatedUser) => onAuthenticationSuccess(authenticatedUser))
      .catch((error) => {
        console.warn("Auth - User not Authenticated: " + error);

        saveRedirectionUrlIfNeeded();

        console.log("Auth - Redirect to cognito");
        window.location.replace(getCognitoUrl());
      });
  }, []);

  const onAuthenticationSuccess = (authenticatedUser: any) => {
    const payload = authenticatedUser
      .getSignInUserSession()
      .getIdToken()
      .decodePayload();
    const authenticatedUserProfile = {
      alias: payload.preferred_username,
      firstName: payload.given_name,
      lastName: payload.family_name,
      email: payload.email,
    };
    setCurrentUser(authenticatedUserProfile);
    console.log("Auth - User Authenticated:", authenticatedUserProfile);

    // redirect to original opened url by user
    console.log("Auth - Checking for redirect url:", window.sessionStorage);
    if (!redirectToOriginalUserRequestedUrlIfNeeded()) {
      console.log(
        "Auth - Setting the IsUserAuthenticated to true",
        window.sessionStorage
      );
      setIsUserAuthenticated(true);
    }
  };

  if (isUserAuthenticated) {
    return (
      <AppContextProvider userProfile={currentUser}>
        <> {children} </>
      </AppContextProvider>
    );
  } else {
    return <Spinner size="large" />;
  }
};
