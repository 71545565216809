import React from "react";
import { UserProfile } from "../types/user.type";

type AppContextType = {
  userProfile: UserProfile | null;
};

export const AppContext = React.createContext<AppContextType>({
  userProfile: null,
});

export type AppContextProviderProps = {
  children: React.ReactNode;
  userProfile: UserProfile | null;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  userProfile,
}) => {
  return (
    <AppContext.Provider value={{ userProfile }}>
      {children}
    </AppContext.Provider>
  );
};
