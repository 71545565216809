import { configsByStage, STAGE_MAPPING } from "./envConfigs";
import { CognitoConfig, Stage } from "./types/configTypes";

export function getStage(): Stage {
  const hostName = window.location.hostname;
  if (!STAGE_MAPPING[hostName]) {
    throw new Error("Invalid host name: " + hostName);
  }

  return STAGE_MAPPING[hostName];
}

export function getCognitoConfig(): CognitoConfig {
  return configsByStage[getStage()].cognito;
}

export function getCognitoUrl(): string {
  const config = getCognitoConfig();
  return `https://${config.oauth.domain}/authorize?client_id=${config.userPoolWebClientId}&response_type=${config.oauth.responseType}&redirect_uri=${config.oauth.redirectSignIn}`;
}

export function getApiBasePath(): string {
  return configsByStage[getStage()].pfsEndpoint;
}
