import BasePage from "./BasePage";
import "./NoContent.css";
export type NoContentInputType = {
  contentOverride?: string;
};

function NoContent(props: NoContentInputType) {
  return (
    <BasePage busy={false}>
      <div className="App">
        <header className="App-header">
          {props.contentOverride || <p>404 - No Content Found in this Path</p>}
        </header>
      </div>
    </BasePage>
  );
}

export default NoContent;
