const REDIRECT_URL_SESSION_KEY = "redirectUrl";

export function saveRedirectionUrlIfNeeded() {
  // save the url opened by user before redirecting to the authentication portal on non-authentication routes
  if (!["/login", "/logout"].includes(window.location.pathname)) {
    console.log(
      "Auth - Save the url opened by user before redirecting to the authentication portal"
    );
    window.sessionStorage.setItem(
      REDIRECT_URL_SESSION_KEY,
      window.location.href
    );
  } else {
    console.log("Auth - Skip saving the url for login and logout routes");
  }
}

export function redirectToOriginalUserRequestedUrlIfNeeded() {
  if (window.sessionStorage.getItem(REDIRECT_URL_SESSION_KEY)) {
    console.log(
      "Auth - Redirect url:",
      window.sessionStorage.getItem(REDIRECT_URL_SESSION_KEY)
    );

    const redirectUrl = window.sessionStorage.getItem(REDIRECT_URL_SESSION_KEY);
    console.log("Auth - Removing redirect url", window.sessionStorage);

    // remove the url from session storage
    window.sessionStorage.removeItem(REDIRECT_URL_SESSION_KEY);

    window.location.replace(redirectUrl!);
    return true;
  }
  return false;
}
